@import "base/experience/variables";
@import "bootstrap/scss/mixins/breakpoints";


/* stylelint-disable-next-line */
.experience-layouts-contentGrid2x3 .margins-enabled {
    margin-top: 16px;

    @include media-breakpoint-up(lg) {
        margin-top: 20px;
    }
}
